'use client';

import { Sparkles } from 'lucide-react';
import { useMemo } from 'react';

import { useRoutes } from '@/hooks/use-routes';

import { type IPricingCardProps, PricingCard } from './pricing-card';

export const Pricing = () => {
  const routes = useRoutes();

  const PRICING: IPricingCardProps[] = useMemo<IPricingCardProps[]>(
    () => [
      {
        title: 'Free',
        popular: false,
        price: 0,
        description: 'Get started with basic features at no cost.',
        buttonText: 'Get started for free',
        benefitList: [
          'Up to 3 Knowledge Bases',
          '500 MB Storage',
          '1,000 pages per month',
          '300,000 search credits per month',
          // 'Limited solver access & answers',
        ],
        onButtonClick: () => {
          routes.goToSearch();
        },
      },
      {
        title: 'Premium',
        popular: true,
        price: 20,
        description: 'Unlock all features with a premium subscription.',
        buttonText: 'Upgrade to Premium',
        benefitList: [
          'Up to 15 Knowledge Bases',
          '15 GB Storage',
          '5,000 pages per month',
          '10 million search credits per month',
          // 'Full solver access & answers',
        ],
        buttonProps: {
          Icon: Sparkles,
          iconLocation: 'start',
          variant: 'default',
        },
        onButtonClick: () => {
          routes.goToCheckout({ tier: 'PREMIUM' }, { newTab: true });
        },
      },
    ],
    [routes],
  );

  return (
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
      {PRICING.map((pricing: IPricingCardProps) => (
        <PricingCard key={pricing.title} {...pricing} />
      ))}
    </div>
  );
};
